import React from "react";
import Navbar from "components/Navbar/navbar";
import Intro3It from "components/Intro3It/intro3It";
import Footer from "components/Footer/footer";
import Services2It from "components/Services2It/services2It";
import DarkTheme from "layouts/Dark";
import cscs from "data/cscs.json";

const Homepage3 = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme>
      <Navbar nr={navbarRef} lr={logoRef} />
      <Intro3It 
        title="Connected Supply Chain Services"
        img="/img/slid/cscs.jpg"
        desc="Elfonze Connected Supply Chain Services is a Continuous Improvement Process of developing Technology based capabilities within Supply Chain Functions at an Organizational Level that enables businesses to deliver best possible products/services at optimized operating costs."
        links={cscs.title} 
      />
      <Services2It style="4item" contentdata={cscs.enterprise} />
      <Footer hideBGCOLOR />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Elfonze Technologies - Connected Supply Chain Services</title>
      <meta key="description" name="description" 
      content="Elfonze Connected Supply Chain Services is a Continuous Improvement Process of developing Technology based capabilities within Supply Chain Functions" />
   
    </>
  )
}

export default Homepage3;
